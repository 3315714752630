import { mainAxios } from "../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "./mainService";

export const getTarjetaInfo = async (numeroTarjeta) => {
  try {
    const serverResponse = await mainAxios.get(
      `/v1/emision/tarjeta/${numeroTarjeta}`,
      getDefaultHeaders()
    );

    // Verificamos si existe información de la tarjeta
    return {
      success: true,
      data: serverResponse.data,
    };
  } catch (error) {
    // Manejamos errores y devolvemos un objeto con el mensaje adecuado
    if (error.response && error.response.status === 400) {
      return {
        success: false,
        message: error.response.data.message || "Error en la solicitud",
      };
    } else {
      throw new Error(getErrorMessage(error));
    }
  }
};
