<template>
  <div style="margin-bottom: 70px;">
    <v-toolbar color="primary" dark rounded="">
      <v-row>
        <v-col cols="6">
          <h2>Detalle Solicitud #{{ $route.params.idSolicitud }}</h2>
        </v-col>

        <v-col cols="6" class="d-flex justify-end">
          <v-btn
            @click="goBack()"
            elevation="2"
            color="#fff"
            class="text--primary"
          >
            <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>Regresar
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-row>
      <v-col
        v-if="
          currentRol == 'ADMIN' ||
            currentRol == 'OPERADORASIST' ||
            currentRol == 'OPERADOR' ||
            currentRol == 'MESADECONTROL' ||
            currentRol == 'MESACONTROLASIST' ||
            currentRol == 'SUPASISTENCIAS'
        "
        cols="12"
      >
        <v-row class="pt-1">
          <v-col v-if="loading" cols="12">
            <v-skeleton-loader type="article, actions"></v-skeleton-loader>
          </v-col>
          <v-col v-else cols="12" class="mt-5">
            <v-row no-gutters>
              <v-col cols="3" class="d-flex">
                <v-btn
                  color="primary darken-3"
                  @click="
                    (openModalEleccion = true),
                      (redimir = true),
                      (cancelar = false)
                  "
                  block
                  text
                  tile
                  class="d-flex justify-start pb-0 mb-0"
                  v-if="currentRol !== 'SUPASISTENCIAS'"
                >
                  <v-icon small class="mr-1">mdi-checkbox-marked-circle</v-icon>
                  <span>Redimir solicitud</span>
                </v-btn>
                <v-divider vertical></v-divider>
              </v-col>
              <v-col cols="3" class="d-flex">
                <v-btn
                  color="primary darken-3"
                  @click="
                    (openModalEleccion = true),
                      (redencionExcepcion = true),
                      (redimir = true),
                      (cancelar = false)
                  "
                  block
                  text
                  class="d-flex justify-start pb-0 mb-0"
                  v-if="
                    solicitud &&
                      (solicitud.status == 'Atención Manual' || solicitud.status == 'Cancelada') &&
                      canRedimirExcepcion
                  "
                >
                  <v-icon small class="mr-1">mdi-checkbox-marked-circle</v-icon>

                  <span>Redimir por excepción</span>
                </v-btn>
                <v-divider vertical></v-divider>
              </v-col>
              <v-col v-if="!!redencion" cols="3" class="d-flex">
                <v-btn
                  color="primary darken-3"
                  @click="reenviarFolio()"
                  block
                  text
                  class="d-flex justify-start pb-0 mb-0"
                >
                  <v-icon small class="mr-1">mdi-message</v-icon>
                  <span>Reenviar SMS folio</span>
                </v-btn>
                <v-divider vertical></v-divider>
              </v-col>
              <v-col cols="3" class="d-flex">
                <v-btn
                  color="primary darken-3"
                  @click="
                    (openModalRedencionManual = true),
                      (redimir = false),
                      (cancelar = false)((redimirManual = true))
                  "
                  block
                  text
                  class="d-flex justify-start pb-0 mb-0"
                  v-if="currentRol !== 'SUPASISTENCIAS'"
                >
                  <v-icon small class="mr-1">mdi-pencil</v-icon>
                  <span>Agregar folio manual</span>
                </v-btn>
                <v-divider vertical></v-divider>
              </v-col>
              <v-col cols="3">
                <v-btn
                  @click="
                    (openModalEleccion = true),
                      (redimir = false),
                      (cancelar = true)
                  "
                  color="primary darken-3"
                  block
                  text
                  class="mb-2 d-flex justify-start pb-0 mb-0"
                  v-if="currentRol !== 'SUPASISTENCIAS'"
                >
                  <v-icon small class="mr-1">mdi-close-circle</v-icon>
                  <span>Cancelar solicitud</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pt-0 mt-0">
        <v-skeleton-loader
          v-if="loading"
          type="article, actions"
          class="mt-3"
        ></v-skeleton-loader>
        <v-row v-else-if="solicitud" class="mt-1">
          <v-col cols="12" class="pt-0 mt-0">
            <v-card
              v-for="(dataCard, index) in dataCards"
              :key="index"
              class="mt-3"
              outlined
            >
              <v-toolbar color="#eceff1" dense flat>
                <v-icon class="mr-2">{{ dataCard.icon }}</v-icon>
                <span>{{ dataCard.title }}</span>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(dataItem, index) in dataCard.dataItems"
                    :key="index"
                    cols="12"
                    :md="dataItem.mdColSize || 4"
                    :lg="dataItem.lgColSize || 2"
                  >
                    <v-text-field
                      :label="dataItem.title"
                      dense
                      filled
                      :value="dataItem.value"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    :key="index"
                    cols="12"
                    :md="4"
                    :lg="2"
                    v-if="celular_saldo"
                  >
                    <v-text-field
                      label="Celular saldo"
                      dense
                      filled
                      :value="celular_saldo"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <CommonConfirmationModal
      :dialog="openModalEleccion"
      @userChoice="userChoice"
    />
    <CommonInputModal
      :dialog="openModalRedencionManual"
      @userChoice="userChoice"
    >
    </CommonInputModal>
  </div>
</template>

<script>
import moment from "moment";
import { getSolicitudById } from "@/services/solicitudService";
import { updateSolicitud } from "@/services/solicitudService";
import CommonConfirmationModal from "@/components/commondComponents/CommonConfirmationModal.vue";
import CommonInputModal from "@/components/commondComponents/CommonInputModal.vue";
import { reenviarSmsFolio } from "@/services/redencionService.js";

export default {
  data() {
    return {
      solicitud: null,
      redencion: null,
      loading: false,
      openModalEleccion: false,
      redimir: false,
      cancelar: false,
      redimirManual: false,
      redencionExcepcion: false,
      openModalRedencionManual: false,
      rolesWithRedimirExcepcion: {
        SUPASISTENCIAS: true,
        OPERACIONESASIST: true,
        ADMINASIST: true,
      },
    };
  },
  components: {
    CommonConfirmationModal,
    CommonInputModal,
  },

  computed: {
    dataCards() {
      if (!this.solicitud) return [];
      const cards = [
        {
          title: "Datos Generales",
          icon: "mdi-text-box",
          dataItems: [
            {
              title: "Título",
              value: this.solicitud.titulo,
            },
            {
              title: "Emisión",
              value: this.solicitud.emisionId,
            },
            {
              title: "Status",
              value: this.solicitud.status,
            },
            {
              title: "Tipo",
              value: this.solicitud.tipo,
            },
            {
              title: "Fecha de creación",
              value: moment(this.solicitud.fechaCreacion, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              ),
            },
            {
              title: "Origen",
              value: this.solicitud.origen,
            },
            ...(this.solicitud.tipo == "Redención" && [
              {
                title: "Beneficio / Asistencia",
                value: this.solicitud.nombreAsistencia,
                mdColSize: 6,
                lgColSize: 6,
              },
            ]),
            {
              title: "Motivo detención (Atención manual)",
              value: this.solicitud.motivo_detencion,
              mdColSize: 6,
              lgColSize: 6,
            },
          ],
        },
        {
          title: "Datos del Cliente",
          icon: "mdi-account",
          dataItems: [
            {
              title: "Nombre",
              value: this.solicitud.nombre,
            },
            {
              title: "Apellido Paterno",
              value: this.solicitud.apellidoPaterno,
            },
            {
              title: "Apellido Materno",
              value: this.solicitud.apellidoMaterno,
            },
            {
              title: "RFC",
              value: this.solicitud.rfc,
            },
            {
              title: "Teléfono de contacto",
              value: this.solicitud.celular,
            },
            {
              title: "Correo de Contacto",
              value: this.solicitud.correoElectronico,
              lgColSize: 4,
            },
          ],
        },
      ];

      if (this.redencion)
        cards.push({
          title: "Redención Obtenida",
          icon: "mdi-ticket",
          dataItems: [
            {
              title: "ID",
              value: this.redencion.id,
            },
            {
              title: "Fecha de redención",
              value: moment(
                this.redencion.fecha_redencion,
                "YYYY-MM-DD HH:mm:ss"
              )
                .subtract(6, "h")
                .format("DD/MM/YYYY HH:mm:ss"),
            },
          ],
        });

      return cards;
    },
    currentRol() {
      let roles = [];
      let CurrentRoles = JSON.parse(localStorage.getItem("roles"));
      CurrentRoles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });
      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },
    celular_saldo(){
      var request_json = JSON.parse(this.solicitud.request)
      return request_json.celular_saldo ?? ''
    },
    canRedimirExcepcion() {
      return this.rolesWithRedimirExcepcion[this.currentRol];
    },
  },

  mounted() {
    this.loadSolicitud();
  },

  methods: {
    goBack() {
      return this.$router.go(-1);
    },

    loadSolicitud() {
      this.loading = true;
      getSolicitudById(this.$route.params.idSolicitud).then((response) => {
        this.solicitud = response.solicitud;
        this.redencion = response.redencion || null;
        this.loading = false;
      });
    },
    userChoice(val, folio) {
      if (val) {
        let body = this.redimir
          ? { redimirBeneficioAsistencia: true }
          : this.cancelar
          ? { status: 8 }
          : this.redimirManual
          ? { redimirBeneficioAsistencia: true, folioManual: folio }
          : {};
        //Se descomento flag
        if (this.redencionExcepcion) body.redencionExcepcion = true;
        this.loading = true;
        updateSolicitud(body, this.$route.params.idSolicitud).then((r) => {
          if (r.status == 400) this.error(r.message);
          else this.success();
        });
      }
      this.openModalEleccion = false;
      this.openModalRedencionManual = false;
    },
    error(e) {
      this.$toast.error(e, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.loading = false;
    },
    success(reload = true) {
      this.$toast.success("Accion realizada con exito ! ", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.loading = false;
      reload && location.reload();
    },

    async reenviarFolio() {
      try {
        await reenviarSmsFolio(this.redencion.id);
        this.success(false);
      } catch (error) {
        this.error(error.message);
      }
    },
  },
};
</script>
