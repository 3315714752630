var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"70px"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","rounded":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Detalle Solicitud #"+_vm._s(_vm.$route.params.idSolicitud))])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text--primary",attrs:{"elevation":"2","color":"#fff"},on:{"click":function($event){return _vm.goBack()}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"}},[_vm._v("mdi-arrow-left")]),_vm._v("Regresar ")],1)],1)],1)],1),_c('v-row',[(
        _vm.currentRol == 'ADMIN' ||
          _vm.currentRol == 'OPERADORASIST' ||
          _vm.currentRol == 'OPERADOR' ||
          _vm.currentRol == 'MESADECONTROL' ||
          _vm.currentRol == 'MESACONTROLASIST' ||
          _vm.currentRol == 'SUPASISTENCIAS'
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pt-1"},[(_vm.loading)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"article, actions"}})],1):_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3"}},[(_vm.currentRol !== 'SUPASISTENCIAS')?_c('v-btn',{staticClass:"d-flex justify-start pb-0 mb-0",attrs:{"color":"primary darken-3","block":"","text":"","tile":""},on:{"click":function($event){(_vm.openModalEleccion = true),
                    (_vm.redimir = true),
                    (_vm.cancelar = false)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-checkbox-marked-circle")]),_c('span',[_vm._v("Redimir solicitud")])],1):_vm._e(),_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3"}},[(
                  _vm.solicitud &&
                    (_vm.solicitud.status == 'Atención Manual' || _vm.solicitud.status == 'Cancelada') &&
                    _vm.canRedimirExcepcion
                )?_c('v-btn',{staticClass:"d-flex justify-start pb-0 mb-0",attrs:{"color":"primary darken-3","block":"","text":""},on:{"click":function($event){(_vm.openModalEleccion = true),
                    (_vm.redencionExcepcion = true),
                    (_vm.redimir = true),
                    (_vm.cancelar = false)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-checkbox-marked-circle")]),_c('span',[_vm._v("Redimir por excepción")])],1):_vm._e(),_c('v-divider',{attrs:{"vertical":""}})],1),(!!_vm.redencion)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"d-flex justify-start pb-0 mb-0",attrs:{"color":"primary darken-3","block":"","text":""},on:{"click":function($event){return _vm.reenviarFolio()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-message")]),_c('span',[_vm._v("Reenviar SMS folio")])],1),_c('v-divider',{attrs:{"vertical":""}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3"}},[(_vm.currentRol !== 'SUPASISTENCIAS')?_c('v-btn',{staticClass:"d-flex justify-start pb-0 mb-0",attrs:{"color":"primary darken-3","block":"","text":""},on:{"click":function($event){(_vm.openModalRedencionManual = true),
                    (_vm.redimir = false),
                    (_vm.cancelar = false)((_vm.redimirManual = true))}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_c('span',[_vm._v("Agregar folio manual")])],1):_vm._e(),_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.currentRol !== 'SUPASISTENCIAS')?_c('v-btn',{staticClass:"mb-2 d-flex justify-start pb-0 mb-0",attrs:{"color":"primary darken-3","block":"","text":""},on:{"click":function($event){(_vm.openModalEleccion = true),
                    (_vm.redimir = false),
                    (_vm.cancelar = true)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-close-circle")]),_c('span',[_vm._v("Cancelar solicitud")])],1):_vm._e()],1)],1)],1)],1)],1):_vm._e(),_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mt-3",attrs:{"type":"article, actions"}}):(_vm.solicitud)?_c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},_vm._l((_vm.dataCards),function(dataCard,index){return _c('v-card',{key:index,staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"#eceff1","dense":"","flat":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(dataCard.icon))]),_c('span',[_vm._v(_vm._s(dataCard.title))]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_vm._l((dataCard.dataItems),function(dataItem,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":dataItem.mdColSize || 4,"lg":dataItem.lgColSize || 2}},[_c('v-text-field',{attrs:{"label":dataItem.title,"dense":"","filled":"","value":dataItem.value,"readonly":""}})],1)}),(_vm.celular_saldo)?_c('v-col',{key:index,attrs:{"cols":"12","md":4,"lg":2}},[_c('v-text-field',{attrs:{"label":"Celular saldo","dense":"","filled":"","value":_vm.celular_saldo,"readonly":""}})],1):_vm._e()],2)],1)],1)}),1)],1):_vm._e()],1)],1),_c('CommonConfirmationModal',{attrs:{"dialog":_vm.openModalEleccion},on:{"userChoice":_vm.userChoice}}),_c('CommonInputModal',{attrs:{"dialog":_vm.openModalRedencionManual},on:{"userChoice":_vm.userChoice}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }