<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Beneficiario</span>
      </v-card-title>

      <v-card-text v-if="dialog">
        <v-form ref="beneficiario">
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="form.status"
                label="Estatus del cliente"
                :items="status"
                item-value="id"
                item-text="name"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                label="Regimen"
                v-model="form.regimen"
                :items="regimenItems"
                :rules="[$rules.required]"
                readonly
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Nombre(s)"
                v-model="form.nombre"
                :rules="[$rules.required]"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Apellido paterno"
                v-model="form.apellidoPaterno"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Apellido materno"
                v-model="form.apellidoMaterno"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="form.genero"
                label="Genero"
                :items="generosBeneficiario"
                item-value="id"
                item-text="sexo"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Fecha nacimiento"
                v-model="form.fechaNacimiento"
                :rules="[validateAge]"
                v-mask="'##/##/####'"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="form.estadoCivil"
                label="Estado civil"
                :items="estadosCiviles"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="RFC"
                v-model="form.rfc"
                :rules="[$rules.required, $rules.rfc, notEqualRfcRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Fecha nacimiento"
                v-model="form.fechaNacimiento"
                :rules="[$rules.required]"
                v-mask="'##/##/####'"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="form.estadoCivil"
                label="Estado civil"
                :items="estadosCiviles"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="RFC"
                v-model="form.rfc"
                :rules="[$rules.required, $rules.rfc, notEqualRfcRule]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-autocomplete
                v-model="form.correo"
                label="Correo Electrónico"
                :items="prediccionesCorreo"
                :filter="filtroCorreo"
                :disabled="canEdit"
                :rules="[$rules.required, $rules.email, validarDominioComun]"
                :append-icon="null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Teléfono"
                v-model="form.telefono"
                v-mask="'##########'"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                label="Parentesco"
                :items="parentesco"
                item-text="descripcion"
                item-value="id"
                v-model="form.parentesco"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                label="porcentaje"
                v-model="form.porcentaje"
                :rules="[
                  $rules.required,
                  $rules.menorIgual(diferencia_porcentaje),
                ]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Creado por"
                v-model="form.creadoPor"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Fecha de creación"
                v-model="form.fechaCreacion"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Modificado por"
                v-model="form.modificadoPor"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Ultima modificación"
                v-model="form.ultimaModificacion"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
        <v-btn v-if="uuid == null" color="primary" text @click="store"
          >Guardar</v-btn
        >
        <v-btn v-else color="primary" text @click="update">Editar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ListExpanded } from "~/plugins/mixins.js";
import { mapActions } from "vuex";
import RfcFacil from "rfc-facil";
import {
  dominiosComunes,
  validarDominioComun,
} from "./ValidacionDominiosComunes";

export default {
  name: "beneficiarios.dialog",
  mixins: [ListExpanded],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
      default: null,
    },
    porcentajeTotal: {
      type: Number,
      default: 100,
    },
    emision: {
      type: Number,
      require: true,
    },
    rfcTitular: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      dialog: false,
      diferencia_porcentaje: 100,
      form: {
        id: null,
        uuid: null,
        emisionId: null,
        status: 0,
        regimen: "Física",
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        genero: null,
        fechaNacimiento: null,
        estadoCivil: null,
        rfc: null,
        correo: null,
        telefono: null,
        parentesco: null,
        porcentaje: null,
        razonSocial: null,

        creadoPor: null,
        fechaCreacion: null,
        modificadoPor: null,
        ultimaModificacion: null,
      },
      busquedaCorreo: "",
    };
  },

  computed: {
    prediccionesCorreo() {
      if (!this.busquedaCorreo.length) return [""];
      if (this.busquedaCorreo.includes("@")) return [this.busquedaCorreo];
      return dominiosComunes.map((e) => `${this.busquedaCorreo}@${e}`);
    },

    fechaNacimientoRequest() {
      if (!this.form.fechaNacimiento) return null;
      const fechaArray = this.form.fechaNacimiento.split("/");
      if (fechaArray.length < 3) return null;
      return `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;
    },
  },

  async mounted() {
    this.dialog = this.open;
    if (this.uuid != null) {
      // en caso de que sea una actualizacion
      await this.getBeneficiario();
    } else {
      // en caso de que no, se toma la suma total
      this.diferencia_porcentaje = 100 - this.porcentajeTotal;
    }
  },
  
  watch: {
    dialog: function(val) {
      this.$emit("update:open", val);
    },
    "form.nombre"() {
      this.actualizarRfc();
    },
    "form.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "form.apellidoMaterno"() {
      this.actualizarRfc();
    },
    "form.fechaNacimiento"() {
      this.actualizarRfc();
    },
  },
  methods: {
    ...mapActions("polizas", ["getBeneficiarios"]),

    validarDominioComun,
    filtroCorreo(item, queryText, itemText) {
      this.busquedaCorreo = queryText;
      return itemText.includes(queryText);
    },

    notEqualRfcRule(v) {
      return (
        v !== this.rfcTitular ||
        "El RFC no puede ser igual al del titular o asegurado"
      );
    },

    actualizarRfc() {
      if (
        !this.form.nombre ||
        !this.form.apellidoPaterno ||
        !this.form.apellidoMaterno ||
        !this.form.fechaNacimiento ||
        this.form.fechaNacimiento.length < 10
      )
        return;

      const arregloNacimiento = this.fechaNacimientoRequest
        .split("-")
        .map((e) => Number(e));
      const rfc = RfcFacil.forNaturalPerson({
        name: this.form.nombre,
        firstLastName: this.form.apellidoPaterno,
        secondLastName: this.form.apellidoMaterno,
        day: arregloNacimiento[2],
        month: arregloNacimiento[1],
        year: arregloNacimiento[0],
      });
      this.form.rfc = rfc.substring(0, rfc.length - 3);
    },

    async getBeneficiario() {
      try {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        let { data } = await this.$axios.get(
          `v1/beneficiario/uuid/${this.uuid}`,
          config
        );
        this.form = data;
        const fNacimientoArray = this.form.fechaNacimiento.split("-");
        this.form.fechaNacimiento = `${fNacimientoArray[2]}/${fNacimientoArray[1]}/${fNacimientoArray[0]}`;
        this.diferencia_porcentaje =
          100 - (this.porcentajeTotal - parseFloat(data.porcentaje));
      } catch (exception) {
        console.error(exception);
      }
    },
    async store() {
      try {
        let valid = this.$refs.beneficiario.validate();
        if (!valid) {
          return;
        }
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        let formdata = {
          emisionId: this.emision,
          status: this.form.status,
          regimen: this.form.regimen,
          nombre: this.form.nombre,
          apellidoPaterno: this.form.apellidoPaterno,
          apellidoMaterno: this.form.apellidoMaterno,
          genero: this.form.genero,
          fechaNacimiento: this.fechaNacimientoRequest,
          estadoCivil: this.form.estadoCivil,
          rfc: this.form.rfc,
          correo: this.form.correo,
          telefono: this.form.telefono,
          parentesco: this.form.parentesco,
          porcentaje: parseFloat(this.form.porcentaje),
          razonSocial: this.form.razonSocial,

          creadoPor: this.form.creadoPor,
          fechaCreacion: this.form.fechaCreacion,
          modificadoPor: this.form.modificadoPor,
          ultimaModificacion: this.form.ultimaModificacion,
        };
        try {
          await this.$axios.post(`v1/beneficiario/insert`, formdata, config);
          await this.getBeneficiarios(formdata.emisionId);
          this.dialog = false;
        } catch (error) {
          const operationalErrorType =
            "com.segurify.util.OperationalException: ";
          if (
            error.response &&
            error.response.data.message.includes(operationalErrorType)
          ) {
            const errorMessage = error.response.data.message.split(
              operationalErrorType
            )[1];
            this.$toast.error(errorMessage);
          }
        }
        // this.$emit('loadData')
      } catch (exception) {
        console.error(exception);
      }
    },
    async update() {
      try {
        let valid = this.$refs.beneficiario.validate();
        if (!valid) {
          return;
        }

        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        let formdata = {
          id: this.form.id,
          uuid: this.form.uuid,
          emisionId: this.form.emisionId,
          status: this.form.status,
          regimen: this.form.regimen,
          nombre: this.form.nombre,
          apellidoPaterno: this.form.apellidoPaterno,
          apellidoMaterno: this.form.apellidoMaterno,
          genero: this.form.genero,
          fechaNacimiento: this.fechaNacimientoRequest,
          estadoCivil: this.form.estadoCivil,
          rfc: this.form.rfc,
          correo: this.form.correo,
          telefono: this.form.telefono,
          parentesco: this.form.parentesco,
          porcentaje: parseFloat(this.form.porcentaje),
          razonSocial: this.form.razonSocial,

          creadoPor: this.form.creadoPor,
          fechaCreacion: this.form.fechaCreacion,
          modificadoPor: this.form.modificadoPor,
          ultimaModificacion: this.form.ultimaModificacion,
        };
        await this.$axios.put(
          `v1/beneficiario/update/${this.uuid}`,
          formdata,
          config
        );
        this.$emit("loadData");
        this.dialog = false;
      } catch (exception) {
        console.error(exception);
      }
    },
  },
};
</script>
