<template>
  <div>
    <commond-form
      :request="formData"
      :route="route"
      :run="run"
      :action="'POST'"
      @cancel="(run = false), (loading = false)"
      @success="success"
      @afterError="afterError"
    >
      <template v-slot:data>
        <v-row dense>
          <v-col cols="12" md="4">
            <v-select
              v-model="formData.origen"
              label="Origen"
              :items="origenes"
              outlined
              :rules="[$rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="formData.status"
              label="Estatus"
              :items="statusSolicitudList"
              item-value="id"
              item-text="title"
              outlined
              :rules="[$rules.required]"
              readonly
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="formData.tipo"
              label="Tipo"
              :items="tipoSolicitudList"
              outlined
              :rules="[$rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="formData.productoId"
              label="Club de beneficios y asistencias"
              :items="productosAdmitidos"
              item-value="id"
              item-text="producto_lead"
              outlined
              :rules="[$rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="formData.beneficioAsistencia"
              label="Beneficio a redimir"
              :items="currentBeneficios"
              item-value="id"
              item-text="nombre"
              outlined
              :rules="[$rules.required]"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="emision" cols="12" md="6">
            <v-text-field
              v-model="notSentFormData.tarjeta"
              label="Tarjeta"
              outlined
              type="number"
              :rules="[$rules.required]"
              counter="16"
              readonly
            ></v-text-field>
          </v-col>
          <v-col v-else cols="12" md="6">
            <v-text-field
              v-model="formData.tarjeta"
              label="Tarjeta"
              outlined
              type="number"
              counter="16"
              v-mask="'################'"
              :rules="[$rules.required, $rules.min(16), $rules.max(16)]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="formData.telefono"
              label="Teléfono para confirmación"
              outlined
              type="number"
              v-mask="'##########'"
              :rules="[$rules.required, $rules.min(10), $rules.max(10)]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" v-if="needTelefonoSaldo">
            <v-text-field
              v-model="formData.celular_saldo"
              label="Numero celular a aplicar Saldo"
              outlined
              type="number"
              v-mask="'##########'"
              :rules="[$rules.required, $rules.min(10), $rules.max(10)]"
            ></v-text-field>
          </v-col>
          <v-col v-if="!emision" cols="12" md="4">
            <v-text-field
              v-model="formData.nombre"
              label="Nombre"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col v-if="!emision" cols="12" md="4">
            <v-text-field
              v-model="formData.apellidoPaterno"
              label="Apellido Paterno"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col v-if="!emision" cols="12" md="4">
            <v-text-field
              v-model="formData.apellidoMaterno"
              label="Apellido Materno"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col v-if="!emision" cols="12" md="4">
            <v-text-field
              v-model="formData.fechaNacimiento"
              label="Fecha de nacimiento"
              outlined
              type="date"
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col v-if="!emision" cols="12" md="8">
            <v-text-field
              v-model="formData.rfc"
              label="RFC"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>
          <v-col v-if="emision" cols="12" md="8">
            <v-text-field
              v-model="notSentFormData.cliente"
              label="Cliente"
              outlined
              :rules="[$rules.required]"
              readonly
            ></v-text-field>
          </v-col>
          <v-col v-if="emision" cols="12" md="4">
            <v-text-field
              v-model="notSentFormData.poliza"
              label="Póliza"
              outlined
              :rules="[$rules.required]"
              readonly
            ></v-text-field>
          </v-col>
          <v-col v-if="needDetalle" cols="12" md="12">
            <v-textarea
              v-model="formData.detalle"
              label="Detalle (Número de cuenta - Número de Teléfono)"
              outlined
              :rules="[$rules.required]"
            ></v-textarea>
          </v-col>
        </v-row>
      </template>
    </commond-form>
    <div class="d-flex justify-end">
      <v-btn
        color="primary darken-2"
        outlined
        class="mr-2"
        @click="$emit('cancel')"
      >
        <span>Cancelar</span>
      </v-btn>
      <v-btn
        color="primary darken-2"
        :loading="loading"
        @click="beforeSend"
      >
        <v-icon class="mr-2">mdi-content-save</v-icon>
        <span>Guardar</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getListaProductos } from "../productos/productosService";
import { getLeadById } from "../leads/leadsService";
import { getBeneficiosList } from "../clubBeneficios/clubBeneficiosService";
import CommondForm from "@/components/commondComponents/CommondForm.vue";
import RfcFacil from "rfc-facil";
import { getTarjetaInfo } from "../../services/tarjetaService";

export default {
  props: {
    emision: {
      type: Object,
      default: () => null,
    },
    route: {
      type: String,
      default: () => "",
    },
  },
  components: {
    CommondForm,
  },

  data() {
    return {
      loading: false,
      isFormValid: false,
      formData: {
        origen: "Portal",
        status: 1,
        productoId: null,
        tipo: "Redención",
        telefono: "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        fechaNacimiento: "",
        tarjeta: "",
        beneficioAsistencia: "",
        redimirBeneficioAsistencia: true,
        detalle:"",
        celular_saldo:''
      },
      origenes: ["Portal"],
      statusSolicitudList: [
        {
          id: 1,
          title: "Nueva",
        },
        {
          id: 2,
          title: "En Trámite",
        },
        {
          id: 3,
          title: "Cerrada",
        },
      ],
      productosList: [],
      beneficiosList: [],
      tipoSolicitudList: ["Redención"],
      lead: null,
      run: false,
      emisionCarga: {},
    };
  },

  computed: {
    notSentFormData() {
      if (!this.emision) return {};
      return {
        ...(this.lead && {
          cliente: `${this.lead.nombre} ${this.lead.apellidoPaterno} ${this.lead.apellidoMaterno}`,
        }),
        poliza: this.emision.poliza,
        tarjeta: this.emision.tarjeta,
      };
    },

    productosAdmitidos() {
      return this.productosList.filter((p) => p.ramo == "ASISTENCIAS");
    },

    needDetalle() {
      if (
        String(this.currentProductName).includes("Didi") ||
        String(this.currentProductName).includes("Rappi") ||
        String(this.currentProductName).includes("Saldo Celular")
      )
        return true;
      else return false;
    },

    currentProductName() {
      if (!this.formData.beneficioAsistencia) return "";
      else
        return this.beneficiosList.find(
          (e) => e.id == this.formData.beneficioAsistencia
        ).nombre;
    },

    currentBeneficios() {
      if (String(this.currentSocioName).includes("C&A"))
        return this.beneficiosList.filter(
          (p) => (p.socio == "C&A" || p.socio == null) && p.status == "Activo"
        );
      else if (this.currentSocioName)
        return this.beneficiosList.filter(
          (p) => (p.socio == "Resto" || p.socio == null) && p.status == "Activo"
        );
      else return [];
    },

    currentSocioName() {
      if (!this.formData.productoId) return "";
      else
        return this.productosAdmitidos.find(
          (e) => e.id == this.formData.productoId
        ).producto_lead;
    },
    needTelefonoSaldo() {
      if (
        String(this.currentProductName).includes("Saldo Celular")
      )
        return true;
      else return false;
    },

    currentRol() {
      let roles = [];
      let current_log_rol = JSON.parse(localStorage.getItem("roles"));
      current_log_rol.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });
      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },
  },
  watch: {
    "formData.fechaNacimiento"() {
      this.getRfc();
    },
    "formData.nombre"() {
      this.getRfc();
    },
    "formData.apellidoPaterno"() {
      this.getRfc();
    },
    "formData.apellidoMaterno"() {
      this.getRfc();
    },
    emisionId() {
      this.getEmision();
    },
    needDetalle(val) {
      if (!val) this.formData.detalle = "";
    },
    'formData.beneficioAsistencia'(){
      if(!this.needTelefonoSaldo)
        this.formData.celular_saldo = ''
    }
  },

  mounted() {
    this.loadProductos();
    this.loadBeneficios();
    if (this.emision) {
      this.formData.productoId = this.emision.producto_id;
      this.loadLead();
    }
  },

  methods: {
    async loadProductos() {
      this.loading = true;
      getListaProductos()
        .then((productos) => {
          this.productosList = productos;
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async loadLead() {
      const leadId = this.emision.lead_id;
      try {
        const response = await getLeadById(leadId);
        this.lead = response.lead;
      } catch (error) {
        this.$toast.error(error.message);
      } finally {
        this.loading = false;
      }
    },

    async loadBeneficios() {
      this.loading = true;
      getBeneficiosList()
        .then((response) => {
          this.beneficiosList = response.clubBeneficios;
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async checkBeforeSend() {
      try {
        const response = await getTarjetaInfo(this.formData.tarjeta);

        if (response.success) {
          this.beforeSend(); // Llama a beforeSend si la tarjeta existe
        } else {
          this.$toast.error(response.message); // Mensaje de error para tarjeta no encontrada
        }
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    getRfc() {
      var partesFecha = this.formData.fechaNacimiento.split("-");
      // Asignar las partes a las variables correspondientes
      var year = partesFecha[0];
      var month = partesFecha[1];
      var day = partesFecha[2];

      this.formData.rfc = RfcFacil.forNaturalPerson({
        name: this.formData.nombre,
        firstLastName: this.formData.apellidoPaterno,
        secondLastName: this.formData.apellidoMaterno,
        day: day,
        month: month,
        year: year,
      });
    },

    beforeSend() {
      if (this.emision) {
        for (let key in this.formData) {
          if (Object.prototype.hasOwnProperty.call(this.notSentFormData, key)) {
            delete this.formData[key];
          }
        }
        this.formData.emisionId = this.emision.id;
      }
      if(!this.needTelefonoSaldo)
          delete this.formData.celular_saldo
      this.run = true;
      this.loading = true;
    },
    success() {
      this.run = false;
      this.loading = false;
      this.$emit("cancel");
    },
    afterError(e) {
      console.log("aqui mero ", e);
      this.run = false;
      this.loading = false;
      this.$emit("cancel");
    },
  },
};
</script>
